<template>
  <div>

    <div class="d-flex w-100">
      <month-picker-input @change="onChangeMonth" :default-month="defaultMonth" />
      <div class="flex-grow-1"></div>
      <b-button variant="outline-primary" class="btn-wishlist" style="padding-top: 14px;" @click="downloadCsv">
        <feather-icon icon="DownloadIcon" class="mr-50" />
        <span>Export</span>
      </b-button>
    </div>
    <div class="mt-2">
      <b-table striped hover :items="logs">
        <template #cell(hours_logged)="data">
          <span class="text-nowrap">
            {{ `${data.item.hours_logged}` }}
          </span>
        </template>
      </b-table>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <div class="triangle"></div>
      <div class="time-wrapper">
        Total: {{ `${Math.floor(hours / 60)} hours ${hours % 60} minute(s)` }}
      </div>
    </div>
  </div>
  <!-- <div class="app-calendar overflow-hidden border bg-white p-1 flex-1"> -->
  <!-- </div> -->
</template>

<script>
import {
  BButton, BTable, BModal, BCardText, BImg
} from 'bootstrap-vue'
import moment from 'moment'
import { MonthPickerInput } from 'vue-month-picker'
import Parse from "parse/dist/parse.min.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { convertMinToHr } from "@core/utils/utils";

export default {
  components: {
    BButton,
    BTable,
    BModal,
    BCardText,
    BImg,
    MonthPickerInput
  },
  data() {
    return {
      defaultMonth: new Date().getMonth() + 1,
      hours: 0,
      items: [
      ],
      openLogsModal: false,
      logs: []
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  methods: {
    onChangeMonth(date) {
      this.loadData(date)
    },
    convertToMins(hours) {
      if (!hours) {
        return 0
      }

      return parseInt(hours.split(":")[0]) * 60 + parseInt(hours.split(":")[1])
    },
    loadData(date) {
      const Booking = Parse.Object.extend("Booking");
      const query = new Parse.Query(Booking);

      const currentUser = Parse.User.current();
      const Assitant = Parse.Object.extend("_User");
      const assistant = new Assitant();
      assistant.id = currentUser.id;
      query.equalTo("assistant", assistant);

      query.include("client")

      query.greaterThanOrEqualTo("start", date.from);
      query.lessThanOrEqualTo("start", date.to);

      // query.exists("time");

      query
        .find()
        .then((results) => {
          const ids = results.map(result => result.id)
          const Log = Parse.Object.extend("Log");
          const logsQuery = new Parse.Query(Log);

          logsQuery.containedIn("booking", ids)
          logsQuery.find().then((logs) => {
            this.hours = 0;

            const bookingIds = [];

            this.logs = logs.map((log) => {
              if (!bookingIds.includes(log.attributes.booking.id)) {
                this.hours += moment.duration(moment(log.attributes.booking.attributes.end).diff(moment(log.attributes.booking.attributes.start))).asMinutes();
                bookingIds.push(log.attributes.booking.id)
              }

              return {
                date: `${moment(log.attributes.booking.attributes.start).format("D MMM YYYY")} ${moment(log.attributes.booking.attributes.start).format("H:mm")} - ${moment(log.attributes.booking.attributes.end).format("H:mm")}`,
                hospital: log.attributes.booking.attributes.client.attributes.name,
                department: log.attributes.booking.attributes.client.attributes.department,
                doctor: log.attributes.doctor,
                description: log.attributes.comments,
              }
            })
          })
        })
        .catch((ex) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching bookings",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    openLogs(data) {
      this.logs = data.logs
      this.openLogsModal = true
    },
    onCloseModal() {
      this.openLogsModal = false
    },
    downloadCsv() {
      let csv = 'Date,Hospital,Department,Doctor,Description\n';
      this.logs.forEach((item) => {
        csv += `${item.date},${item.hospital},${item.department},${item.doctor},${item.description}`
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'timesheet.csv';
      anchor.click();
    }
  }
};
</script>

<style lang="scss">
.month-picker__year {
  button {
    padding-bottom: 41px;
  }
}

.month-picker-input {}

.time-wrapper {
  height: 38px;
  width: 300px;
  background: rgba(115, 103, 240, 0.12);
  padding: 10px;
  font-weight: 700;
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid rgba(115, 103, 240, 0.12);

  border-top: 20px solid rgba(115, 103, 240, 0.12);
  border-bottom: 20px solid transparent
}

.preview_icon {
  display: inline-block;
  margin-left: 20px;
}

.eye-icon {
  margin-top: -2px;
}


.icon {
  color: #7367F0;
  height: 24px;
}

.button-icon {
  margin-right: 4px;
}

.log-container {
  border-radius: 8px;
  border: 1px solid #EDEDFE;

  .header {
    padding: 6px;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #EDEDFE;

    .cursor-pointer {
      color: #484848;
      margin: 3px;
    }
  }

  img {
    margin-left: 4px;
    margin-right: 4px;
  }

  .name {
    color: #7367F0;
    margin-top: 2px;
  }

  .comment {
    font-size: 14px;
  }

  .updatedAt {
    font-style: italic;
    margin-bottom: 0 !important;
    font-size: 10px;
  }

}
</style>
